import React, { useState } from 'react'
import { IGif } from '@giphy/js-types'

import { basePhotoUrl } from '../../utils/FirestoreUtils'
import { ElementData } from '../Room/Types'
import GiphySearch from './GiphySearch'
import PhotoUpload from './PhotoUpload'
import { useRoom } from '../Room/RoomProvider'

type AddGraphicProps = {
  element: ElementData
}

const AddGraphic: React.FC<AddGraphicProps> = (props: AddGraphicProps) => {
  const { element } = props
  const { saveElement } = useRoom()
  const [uploadImageUrl, setUploadImageUrl] = useState<string | undefined>(
    undefined
  )

  const onUpload = async (filename: string) => {
    const updatedElement = {
      ...element,
      content: {
        url: `${basePhotoUrl}${filename}?alt=media`,
      },
    }
    await saveElement(updatedElement, element.id)
  }

  const onGifClick = async (gif: IGif) => {
    const updatedElement = {
      ...element,
      content: {
        url: gif.images.original.webp,
      },
    }
    await saveElement(updatedElement, element.id)
  }

  return (
    <>
      {uploadImageUrl ? (
        <img width='100%' src={uploadImageUrl} alt='Uploaded' />
      ) : (
        <GiphySearch onGifClick={onGifClick} />
      )}
      <PhotoUpload
        id={element.id}
        onUpload={onUpload}
        imageObjectUrl={uploadImageUrl}
        setImageObjectUrl={setUploadImageUrl}
      />
    </>
  )
}

export default AddGraphic
