import React from 'react'

import styles from './TaskBar.module.scss'

const Divider: React.FC = () => (
  <div className={styles.Divider}>
    <div />
    <div />
  </div>
)

export default Divider
