import React, { useState } from 'react'

import { useRoom } from '../Room/RoomProvider'
import { ElementData } from '../Room/Types'
import { generateSpotifyEmbedSrc } from './SpotifyUtils'
import spotifyImage from '../../assets/spotify-transparent.png'

import styles from './SpotifyPlayer.module.scss'

type Props = {
  element: ElementData
}

const SpotifyEmpty: React.FC<Props> = ({ element }: Props) => {
  const [url, setUrl] = useState('')
  const [error, setError] = useState(false)
  const { saveElement } = useRoom()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setError(false)
    const src = generateSpotifyEmbedSrc(url)

    if (!src) {
      return setError(true)
    }

    saveElement(
      {
        ...element,
        content: { url },
      },
      element.id
    )
  }

  return (
    <form className={styles.SpotifyEmpty} onSubmit={onSubmit}>
      <div>
        <img alt='spotify logo' src={spotifyImage} width={64} height={64} />
        <p>
          Paste a link to a
          <br />
          Spotify Playlist
        </p>
        <input
          type='text'
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder='Link to Spotify Playlist'
        />
        {error && <div className={styles.ErrorMessage}>Link invalid</div>}
      </div>
      <div className={styles.Actions}>
        {url && <input type='submit' value='Play' />}
      </div>
    </form>
  )
}

export default SpotifyEmpty
