import React from 'react'
import Youtube from 'react-youtube'

type YoutubeIframeProps = {
  src: string
  type: 'playlist' | 'video'
  time?: number
}

const YoutubeIframe: React.FC<YoutubeIframeProps> = ({
  src,
  type,
  time,
}: YoutubeIframeProps) => {
  const opts: any = {
    height: '315',
    width: '560',
    playerVars: {
      autoplay: 1,
    },
  }

  if (time) {
    opts.playerVars.start = time
  }

  if (type === 'playlist') {
    opts.playerVars.listType = 'playlist'
    opts.playerVars.list = src
  }

  return <Youtube videoId={src} opts={opts} />
}

export default YoutubeIframe
