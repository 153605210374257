import React, { useState } from 'react'
import { Loader, Modal } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { useAsyncCallback } from 'react-async-hook'

import { useRoom } from '../Room/RoomProvider'
import defaultBackground from '../../assets/clouds.jpeg'
import mixesImage from '../../assets/mixes-title-icon.png'
import styles from './Modals.module.scss'

import { uploadFile } from '../../utils/FileUploader'
import { VibeData } from '../Room/Types'
import { basePhotoUrl } from '../../utils/FirestoreUtils'
import Window from './Window'

const CreateMixModal: React.FC = () => {
  const {
    showCreateMix,
    setShowCreateMix,
    saveVibe,
    room,
    getVibe,
    vibes,
    createVibe,
  } = useRoom()
  const { roomId } = useParams()
  const [name, setName] = useState('')
  const [image, setImage] = useState<File | undefined>(undefined)
  const [imageUrl, setImageUrl] = useState('')

  const uploadBackgroundImage = async (vibeId: string) => {
    const filename = await uploadFile(image as File, vibeId + Date.now())
    const vibe = (await getVibe(vibeId)).data() as VibeData
    saveVibe(
      {
        ...vibe,
        background: `${basePhotoUrl}${filename}?alt=media`,
      },
      vibeId
    )
  }

  const createVibeAsync = useAsyncCallback(async () => {
    if (!room) return

    const vibeId = await createVibe(roomId as string, name)

    if (image) {
      await uploadBackgroundImage(vibeId)
    }
    setShowCreateMix(false)
  })

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!name || createVibeAsync.loading || vibes.length >= 10) return
    createVibeAsync.execute()
  }

  const onImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files.length === 0) return
    const image = files[0]
    setImage(image)
    setImageUrl(URL.createObjectURL(image))
  }

  return (
    <Modal
      open={showCreateMix}
      onClose={() => setShowCreateMix(false)}
      size='mini'
      className={styles.CreateMixModal}
    >
      <Window
        title={
          <div className={styles.CompositeTitle}>
            <img src={mixesImage} alt='mix icon' width='16' height='16' />{' '}
            Create a new mix
          </div>
        }
        onClose={() => setShowCreateMix(false)}
      >
        <form onSubmit={onSubmit}>
          <div className={styles.Field}>
            <label>Name your Mix</label>
            <input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='80s Fever Dream'
            />
          </div>

          <div className={styles.Field}>
            <label>Set Wallpaper</label>
            <label htmlFor='create_wallpaper'>
              <div className={styles.PreviewContainer}>
                <img alt='Uploaded' src={imageUrl || defaultBackground} />
                <div className='button'>Upload</div>
              </div>

              <input
                type='file'
                id='create_wallpaper'
                name='create_wallpaper'
                accept='image/png, image/jpeg'
                onChange={onImageSelect}
              />
            </label>
          </div>

          <div className={styles.Actions}>
            <button
              onClick={() => setShowCreateMix(false)}
              className='transparent'
            >
              Cancel
            </button>
            <button disabled={createVibeAsync.loading || !name}>
              {createVibeAsync.loading ? (
                <Loader
                  inline
                  size='tiny'
                  style={{ width: '50px', height: '18px' }}
                />
              ) : (
                'Create'
              )}
            </button>
          </div>
        </form>
      </Window>
    </Modal>
  )
}

export default CreateMixModal
