import React from 'react'
import { useNavigate } from 'react-router-dom'
import { generateSlug } from 'random-word-slugs'

import backgroundImage from '../../assets/groovy-background.jpeg'
import Welcome from '../Welcome'

const getRandomSlug = () => {
  return generateSlug(3, { format: 'lower' }).replaceAll(' ', '-')
}

const RoomCreator: React.FC = () => {
  const navigate = useNavigate()

  const goToSpace = () => {
    sessionStorage.setItem('hasClicked', '1')
    navigate(`/${getRandomSlug()}`)
  }

  return (
    <Welcome
      radioName='radio'
      backgroundImage={backgroundImage}
      onOpen={() => goToSpace()}
    />
  )
}

export default RoomCreator
