import React, { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'
import Draggable from 'react-draggable'
import { ResizableBox, ResizeCallbackData } from 'react-resizable'
import classNames from 'classnames'

import YoutubePlayer from '../YoutubePlayer'
import SpotifyPlayer from '../SpotifyPlayer'
import { ElementData, ElementType } from '../Room/Types'
import Graphic from '../Graphic'
import { initializeFirestore } from '../../utils/FirestoreUtils'
import closeIcon from '../../assets/close-icon.svg'
import folderIcon from '../../assets/folder-2-icon.svg'
import mixesImage from '../../assets/mixes-title-icon.png'
import spotifyImage from '../../assets/spotify-title-icon.png'
import youtubeImage from '../../assets/youtube-title-icon.png'
import giphyImage from '../../assets/image-title-icon.png'
import remotionImage from '../../assets/remotion-title-icon.png'

import styles from './Canvas.module.scss'
import { useRoom } from '../Room/RoomProvider'

type CanvasSize = {
  width: number
  height: number
}

type Position = {
  x: number
  y: number
}

type DraggableElementProps = {
  elementId?: string
  canvasSize: CanvasSize
  onClose: () => void
  onSelected: () => void
  selected: boolean
  isLocal?: boolean
  children?: React.ReactNode
  defaultSize?: CanvasSize
  defaultPosition?: Position
  type?: ElementType
}

const getAbsolutePosition = (position: Position, canvasSize: CanvasSize) => {
  return {
    x: (position.x * canvasSize.width) / 100,
    y: (position.y * canvasSize.height) / 100,
  }
}

const limitPosition = (
  position: Position,
  canvasSize: CanvasSize
): Position => {
  const newPosition = { ...position }
  if (position.x < 0) {
    newPosition.x = 0
  }
  if (position.y < 0) {
    newPosition.y = 0
  }
  if (position.x > canvasSize.width - 40) {
    newPosition.x = canvasSize.width - 40
  }
  if (position.y > canvasSize.height - 75) {
    newPosition.y = canvasSize.height - 75
  }
  return newPosition
}

const getPercentPosition = (position: Position, canvasSize: CanvasSize) => {
  const percentPosition = {
    x: (position.x * 100) / canvasSize.width,
    y: (position.y * 100) / canvasSize.height,
  }
  return percentPosition
}

const canResize = (type: ElementType | undefined) => {
  switch (type) {
    case ElementType.Image:
      return true
    case ElementType.Spotify:
    case ElementType.Youtube:
    case ElementType.VibeExplorer:
    case ElementType.About:
    case ElementType.Share:
      return false
    default:
      return false
  }
}

type WindowInfo = {
  title: string
  icon: string
}

const getWindowInfo = (type: ElementType): WindowInfo => {
  const elementTypes: any = {
    [ElementType.VibeExplorer]: {
      title: 'Set mix',
      icon: mixesImage,
    },
    [ElementType.Spotify]: {
      title: 'Spotify player',
      icon: spotifyImage,
    },
    [ElementType.Youtube]: {
      title: 'YouTube media player',
      icon: youtubeImage,
    },
    [ElementType.About]: {
      title: 'About wfh.fm',
      icon: remotionImage,
    },
    [ElementType.Image]: {
      title: 'Image viewer',
      icon: giphyImage,
    },
  }
  return (
    elementTypes[type] || {
      title: type.toString(),
      icon: folderIcon,
    }
  )
}

const DraggableElement: React.FC<DraggableElementProps> = (
  props: DraggableElementProps
) => {
  const { elementsCollectionRef } = initializeFirestore()
  const { saveElement, onElementLoad } = useRoom()
  const [loaded, setLoaded] = useState(false)

  const {
    elementId,
    canvasSize,
    onClose,
    onSelected,
    selected,
    isLocal,
    children,
    type,
    defaultPosition = { x: 0, y: 0 },
    defaultSize = { width: 0, height: 0 },
  } = props
  const [element, setElement] = useState<ElementData | undefined>(undefined)
  const [size, setSize] = useState(defaultSize)
  const [position, setPosition] = useState(defaultPosition)

  useEffect(() => {
    if (!elementId) return
    const unsub = onSnapshot(
      doc(elementsCollectionRef, elementId),
      (snapshot) => {
        setElement({
          id: snapshot.id,
          ...(snapshot.data() as any),
        })
      }
    )
    return () => {
      unsub()
    }
  }, [elementId])

  useEffect(() => {
    if (!element || loaded) return
    onElementLoad(element)
    setLoaded(true)
  }, [element, loaded, onElementLoad])

  const dragElement = (x: number, y: number) => {
    if (!element && elementId) return
    const limitedPosition = limitPosition({ x, y }, canvasSize)
    const percentPosition = getPercentPosition(limitedPosition, canvasSize)
    setPosition(limitedPosition)
    if (!isLocal) {
      saveElement(
        {
          ...element,
          position: percentPosition,
        } as any,
        elementId
      )
    }
  }

  const onResize = (_e: React.SyntheticEvent, data: ResizeCallbackData) => {
    if (!element) return
    setSize(data.size)
    if (!isLocal) {
      saveElement(
        {
          ...element,
          size: data.size,
        } as any,
        elementId
      )
    }
  }

  useEffect(() => {
    if (!element) return
    if (!loaded) {
      setPosition(getAbsolutePosition(element.position, canvasSize))
    }
    setSize(element.size)
  }, [element, canvasSize, loaded])

  if (!element && elementId) return null

  const elementType = (type || element?.type) as ElementType

  const windowInfo = getWindowInfo(elementType)

  return (
    <Draggable
      axis='both'
      position={{
        x: position.x,
        y: position.y,
      }}
      onStop={(_e, data) => dragElement(data.x, data.y)}
      onMouseDown={onSelected}
      handle='.menubar'
    >
      <div
        className={classNames(styles.DraggableElement, {
          [styles.selected]: selected,
        })}
      >
        <div className={classNames(styles.FrameDecoration, styles.left)} />
        <div className={classNames(styles.FrameDecoration, styles.top)} />
        <div className={classNames(styles.FrameDecoration, styles.bottom)} />
        <div className={classNames(styles.FrameDecoration, styles.right)} />
        <div className={styles.Background} />
        <div className={styles.DraggableFrame}>
          <div className={classNames(styles.MenuBar, 'menubar')}>
            <div>
              <img
                alt='title bar icon'
                src={windowInfo.icon}
                width={16}
                height={16}
              />
              {windowInfo.title}
            </div>
            {(elementType === ElementType.Image || !elementId) && (
              <img
                src={closeIcon}
                onClick={() => onClose()}
                alt='close'
                style={{ cursor: 'pointer' }}
                className={styles.CloseIcon}
              />
            )}
          </div>
          <ResizableBox
            height={size.height}
            width={size.width}
            axis={canResize(elementType) ? 'both' : 'none'}
            resizeHandles={['se']}
            handle={
              canResize(elementType) ? (
                <div className={styles.ResizeHandle}>+</div>
              ) : null
            }
            handleSize={[15, 15]}
            onResizeStop={onResize}
          >
            <div className={styles.ElementContainer}>
              {element && (
                <>
                  {elementType === ElementType.Youtube && (
                    <YoutubePlayer element={element} />
                  )}
                  {elementType === ElementType.Spotify && (
                    <SpotifyPlayer element={element} />
                  )}
                  {elementType === ElementType.Image && (
                    <Graphic element={element} />
                  )}
                </>
              )}

              {children}
            </div>
          </ResizableBox>
        </div>
      </div>
    </Draggable>
  )
}

export default DraggableElement
