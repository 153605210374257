import React from 'react'

import styles from './DesktopIcons.module.scss'

type Props = {
  children: React.ReactNode
  onClick?: () => void
}

const Icon: React.FC<Props> = ({ children, onClick }: Props) => {
  return (
    <div className={styles.DesktopIcon} onDoubleClick={onClick}>
      {children}
    </div>
  )
}

export default Icon
