import React from 'react'

import styles from './Menu.module.scss'
import MenuDivider from './MenuDivider'
import folderIcon from '../../assets/folder-icon.svg'
import classNames from 'classnames'
import { useAsyncCallback } from 'react-async-hook'
import { uploadFile } from '../../utils/FileUploader'
import { useRoom } from '../Room/RoomProvider'
import { basePhotoUrl } from '../../utils/FirestoreUtils'

type Props = {
  showIcons?: boolean
  showTitle?: boolean
  showChangeWallpaper?: boolean
}

const Menu: React.FC<Props> = ({
  showIcons,
  showTitle,
  showChangeWallpaper,
}: Props) => {
  const icon = showIcons ? <img alt='folder icon' src={folderIcon} /> : null

  const {
    activeVibe,
    saveVibe,
    setShowVibeExplorer,
    setShowCreateMix,
    setActiveElement,
    setShowAbout,
    setShowShare,
    viewOnly,
    setShowViewOnlyError,
    vibes,
  } = useRoom()

  const uploadBackgroundImage = useAsyncCallback(async (image: File) => {
    if (!activeVibe) return
    const filename = await uploadFile(image, activeVibe.id + Date.now())
    await saveVibe(
      {
        ...activeVibe,
        background: `${basePhotoUrl}${filename}?alt=media`,
      },
      activeVibe.id
    )
  })

  const onImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files.length === 0) return
    const image = files[0]
    uploadBackgroundImage.execute(image)
  }

  const openShare = () => {
    if (viewOnly) {
      navigator.clipboard.writeText(window.location.href)
    } else {
      setShowShare(true)
      setActiveElement('share')
    }
  }

  const openCreateMix = () => {
    if (viewOnly) {
      setShowViewOnlyError(true)
    } else {
      setShowCreateMix(true)
    }
  }

  const openImageSelect = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    if (viewOnly) {
      e.preventDefault()
      setShowViewOnlyError(true)
    }
  }

  return (
    <div className={styles.Menu}>
      {showTitle && (
        <div className={classNames(styles.Title)}>radioOS v1.0</div>
      )}
      <div className={styles.Row} onClick={openShare}>
        {icon} {viewOnly ? 'Copy link to radio' : 'Share'}
      </div>
      {showChangeWallpaper && (
        <div className={styles.Row}>
          <label htmlFor='wallpaper' onClick={openImageSelect}>
            <div>{icon} Change Wallpaper</div>
            <input
              type='file'
              id='wallpaper'
              name='wallpaper'
              accept='image/png, image/jpeg'
              onChange={onImageSelect}
            />
          </label>
        </div>
      )}
      <MenuDivider />
      <div
        className={styles.Row}
        onClick={() => {
          setShowVibeExplorer(true)
          setActiveElement('vibes')
        }}
      >
        {icon} Explore Mixes
      </div>
      {vibes.length < 10 && (
        <div className={styles.Row} onClick={openCreateMix}>
          {icon} Create New Mix
        </div>
      )}
      <MenuDivider />
      <div className={styles.Row} onClick={() => setShowAbout(true)}>
        {icon} About
      </div>
    </div>
  )
}

export default Menu
