import React, { useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'

import { uploadFile } from '../../utils/FileUploader'
import styles from './Graphic.module.scss'

type PhotoUploadProps = {
  id: string
  onUpload: (filename: string) => void
  imageObjectUrl?: string
  setImageObjectUrl: (url?: string) => void
}

const PhotoUpload: React.FC<PhotoUploadProps> = (props: PhotoUploadProps) => {
  const { id, onUpload, imageObjectUrl, setImageObjectUrl } = props
  const [imageFile, setImageFile] = useState<File | undefined>()

  const uploadImage = useAsyncCallback(async () => {
    if (!imageFile) return
    const result = await uploadFile(imageFile, id)
    onUpload(result)
  })

  const onImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files.length === 0) return
    const image = files[0]
    setImageFile(image)
    setImageObjectUrl(URL.createObjectURL(image))
  }

  const cancelUpload = () => {
    setImageFile(undefined)
    setImageObjectUrl(undefined)
  }

  return (
    <div className={styles.UploadImageContainer}>
      {imageObjectUrl ? (
        <>
          <button className='transparent' onClick={cancelUpload}>
            Back
          </button>
          <button onClick={() => uploadImage.execute()}>Set</button>
        </>
      ) : (
        <label htmlFor='avatar' className={styles.UploadImageButton}>
          Upload image
          <input
            type='file'
            id='avatar'
            name='avatar'
            accept='image/png, image/jpeg'
            onChange={onImageSelect}
          />
        </label>
      )}
    </div>
  )
}

export default PhotoUpload
