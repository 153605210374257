export const generateSpotifyEmbedSrc = (url?: string) => {
  if (!url) return null
  try {
    const urlComponent = new URL(url)
    if (urlComponent.hostname.includes('open.spotify.com')) {
      return `https://open.spotify.com/embed${urlComponent.pathname}`
    }
  } catch (error) {
    return null
  }
  return null
}
