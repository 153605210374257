import React, { useContext } from 'react'
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from '@giphy/react-components'
import { IGif } from '@giphy/js-types'

import poweredByGiphyImage from '../../assets/powered-by-giphy.png'
import styles from './Graphic.module.scss'

// https://github.com/Giphy/giphy-js/blob/master/packages/react-components/README.md
const GIPHY_SDK_KEY = 'ND3lnHNysPtdsaTef4MDBK8jOpT9KDWt'

type GiphySearchProps = {
  onGifClick: (gif: IGif) => void
}

// define components separately so we can use the context hook
const Components: React.FC<GiphySearchProps> = (props: GiphySearchProps) => {
  const { fetchGifs, searchKey } = useContext(SearchContext)

  return (
    <>
      <SearchBar className={styles.GiphySearchBar} />
      <Grid
        className={styles.GiphyGrid}
        key={searchKey}
        columns={2}
        width={300}
        fetchGifs={fetchGifs}
        noLink={true}
        onGifClick={props.onGifClick}
      />
      <img
        className={styles.GiphyAttribution}
        src={poweredByGiphyImage}
        alt='powered by giphy'
      />
    </>
  )
}

// the search experience consists of the manager and its child components that use SearchContext
const GiphySearch: React.FC<GiphySearchProps> = (props: GiphySearchProps) => (
  <SearchContextManager apiKey={GIPHY_SDK_KEY} theme={{ searchbarHeight: 30 }}>
    <Components onGifClick={props.onGifClick} />
  </SearchContextManager>
)

export default GiphySearch
