import React, { FormEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'

import { useRoom } from '../Room/RoomProvider'
import { RoomData } from '../Room/Types'

import styles from './Share.module.scss'

const Share = () => {
  const { roomId = '' } = useParams()
  const { room, getRoom, saveRoom, createRoom, setRedirectPassword } = useRoom()
  const roomName = room?.name || ''

  const [stationName, setStationName] = useState(roomName)
  const [slug, setSlug] = useState(roomId)
  const [linkCopied, setLinkCopied] = useState(false)
  const [publicLinkCopied, setPublicLinkCopied] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const nameChanged = roomName !== stationName
  const slugChanged = roomId !== slug
  const hasChanges = nameChanged || slugChanged

  const saveChanges = async (e: FormEvent) => {
    e.preventDefault()
    if (!slug) {
      setError('url cannot be blank')
      return
    }
    if (loading) return

    setError('')
    setLinkCopied(false)
    setPublicLinkCopied(false)
    setLoading(true)

    if (nameChanged) {
      await saveRoom(
        {
          ...(room as RoomData),
          name: stationName,
        },
        roomId
      )
    }
    if (slugChanged) {
      const roomSnap = await getRoom(slug)
      // check for url availability
      if (roomSnap.exists()) {
        setError('this url is already taken')
        setLoading(false)
        return
      }

      // create new room with the current data
      const { password } = await createRoom(slug, {
        ...(room as RoomData),
        name: stationName,
      })
      setRedirectPassword(password)

      // save new url on old room, redirect happens in useEffect in Room/Room.tsx
      await saveRoom({ ...(room as RoomData), redirectTo: slug }, roomId)
    }
    setLoading(false)
  }

  const cancel = () => {
    setError('')
    setStationName(roomName)
    setSlug(roomId)
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href)
    setLinkCopied(true)
  }

  const copyPublicLink = () => {
    const url = new URL(window.location.href)
    navigator.clipboard.writeText(`${url.origin}${url.pathname}`)
    setPublicLinkCopied(true)
  }

  return (
    <div className={styles.Share}>
      <div className={styles.Header}>Share Link</div>

      <div className={styles.ShareLinkRow}>
        <label htmlFor='copy-link'>For collaborators</label>
        <button
          type='button'
          disabled={slugChanged}
          onClick={copyLink}
          name='copy-link'
        >
          {linkCopied ? 'Copied!' : 'Copy link'}
        </button>
      </div>

      <div className={styles.ShareLinkRow}>
        <label htmlFor='copy-public-link'>For viewers</label>
        <button
          type='button'
          disabled={slugChanged}
          onClick={copyPublicLink}
          name='copy-public-link'
        >
          {publicLinkCopied ? 'Copied!' : 'Copy link'}
        </button>
      </div>

      <div className={styles.Divider} />

      <div className={styles.Header}>Personalize</div>

      <form onSubmit={saveChanges}>
        <div className={styles.PersonalizeRow}>
          <label htmlFor='station-name'>Station Name</label>
          <input
            name='station-name'
            type='text'
            placeholder='Team Name'
            value={stationName}
            onChange={(e) => setStationName(e.target.value)}
          />
        </div>

        <div className={styles.PersonalizeRow}>
          <label htmlFor='url'>wfh.fm/</label>
          <input
            required
            name='url'
            type='text'
            pattern='[A-Za-z0-9_~\-]+'
            title='Only alphanumeric and special characters _-~ allowed'
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </div>

        {hasChanges && (
          <div className={styles.Actions}>
            <button type='button' onClick={cancel} disabled={loading}>
              Cancel
            </button>
            <button type='submit' disabled={loading}>
              {loading ? (
                <Loader
                  inline
                  size='tiny'
                  active
                  inverted
                  style={{ width: '100px', height: '18px' }}
                />
              ) : (
                'Save changes'
              )}
            </button>
          </div>
        )}
      </form>
      {error && <p className={styles.Error}>{error}</p>}
    </div>
  )
}

export default Share
