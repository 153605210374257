import React from 'react'
import { Loader, Modal } from 'semantic-ui-react'

import Window from './Window'
import errorIcon from '../../assets/error-icon.png'
import styles from './Modals.module.scss'

type Props = {
  mixName: string
  onCancel: () => void
  onConfirm: () => void
  loading?: boolean
}

const DeleteMixModal: React.FC<Props> = ({
  mixName,
  onCancel,
  onConfirm,
  loading,
}: Props) => {
  return (
    <Modal
      open
      onClose={onCancel}
      size='mini'
      className={styles.DeleteMixModal}
    >
      <Window title='Delete mix' onClose={onCancel}>
        <div className={styles.Message}>
          <img src={errorIcon} alt='warning' />
          <p>Deleting {mixName} will remove it for everyone</p>
        </div>

        <div className={styles.Actions}>
          <button onClick={onCancel}>Cancel</button>
          <button disabled={loading} onClick={onConfirm}>
            {loading ? (
              <Loader
                inline
                size='tiny'
                style={{ width: '50px', height: '18px' }}
              />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </Window>
    </Modal>
  )
}

export default DeleteMixModal
