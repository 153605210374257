import React, { useState, useEffect } from 'react'

import { ElementData } from '../Room/Types'
import SpotifyEmpty from './SpotifyEmpty'
import SpotifyIframe from './SpotifyIframe'
import { generateSpotifyEmbedSrc } from './SpotifyUtils'
import { useRoom } from '../Room/RoomProvider'
import visualizerImage from '../../assets/visualizer.png'
import styles from './SpotifyPlayer.module.scss'

type SpotifyPlayerProps = {
  element: ElementData
}

const SpotifyPlayer: React.FC<SpotifyPlayerProps> = ({ element }) => {
  const { content } = element
  const [src, setSrc] = useState('')
  const { saveElement, viewOnly, setShowViewOnlyError } = useRoom()

  useEffect(() => {
    const src = generateSpotifyEmbedSrc(content.url)
    setSrc(src || '')
  }, [content.url])

  const resetPlayer = () => {
    if (viewOnly) {
      return setShowViewOnlyError(true)
    }

    saveElement(
      {
        ...element,
        content: {},
      },
      element.id
    )
  }

  return (
    <div style={{ height: '100%' }}>
      {src ? (
        <div className={styles.SpotifyPlayer}>
          <div className={styles.ResetMenu}>
            <button className='transparent' onClick={resetPlayer}>
              Load New Playlist
            </button>
          </div>
          <div className={styles.Content}>
            <div className={styles.Visualizer}>
              <img alt='decorative' src={visualizerImage} />
            </div>
            <div className={styles.PlayerContainer}>
              <SpotifyIframe src={src} />
            </div>
          </div>
        </div>
      ) : (
        <SpotifyEmpty element={element} />
      )}
    </div>
  )
}

export default SpotifyPlayer
