import React from 'react'
import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import { connectFunctionsEmulator } from 'firebase/functions'

import firebaseConfig from './configs/firebaseConfig.json'
import './App.scss'
import RoomCreator from './components/RoomCreator'
import Room from './components/Room'

const firebaseApp = initializeApp(firebaseConfig)
export const functions = getFunctions(firebaseApp)
export const analytics = getAnalytics(firebaseApp)

// uncomment to use emulator
// connectFunctionsEmulator(functions, 'localhost', 5001)

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<RoomCreator />} />
      <Route path='/:roomId' element={<Room />} />
    </Routes>
  </BrowserRouter>
)

export default App
