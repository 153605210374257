import React, { useEffect, useState } from 'react'
import getYouTubeID from 'get-youtube-id'

import YoutubeIframe from './YoutubeIframe'
import { ElementData } from '../Room/Types'
import { useRoom } from '../Room/RoomProvider'
import youtubeEmptyImage from '../../assets/youtube-empty.png'
import styles from './YoutubePlayer.module.scss'

type YoutubePlayerProps = {
  element: ElementData
}

const generateYoutubeEmbedSrc = (url?: string) => {
  if (!url) return null
  let time
  try {
    const urlComponent = new URL(url)
    const playlistID = urlComponent.searchParams.get('list')
    time = urlComponent.searchParams.get('t')
    if (time) {
      time = time.replaceAll('s', '')
    }
    if (playlistID && urlComponent.hostname.includes('youtube.com')) {
      return {
        src: playlistID,
        type: 'playlist',
      }
    }
  } catch (error) {
    return null
  }

  const videoID = getYouTubeID(url)
  if (videoID) {
    return {
      src: videoID,
      type: 'video',
      time,
    }
  }

  return null
}

const YoutubePlayer: React.FC<YoutubePlayerProps> = ({ element }) => {
  const { content } = element
  const [url, setUrl] = useState('')
  const [video, setVideo] = useState<any>(undefined)
  const [error, setError] = useState(false)
  const { saveElement, viewOnly, setShowViewOnlyError } = useRoom()

  useEffect(() => {
    const result = generateYoutubeEmbedSrc(content.url)
    if (result) {
      setVideo(result)
    }
  }, [content.url])

  const submitForm = () => {
    setError(false)

    const result = generateYoutubeEmbedSrc(url)

    if (!result) {
      setTimeout(() => setError(false), 5000)
      return setError(true)
    }

    saveElement(
      {
        ...element,
        content: { url },
      },
      element.id
    )
    setVideo(result)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (viewOnly) {
      setShowViewOnlyError(true)
    } else {
      submitForm()
    }
  }

  return (
    <div className={styles.YoutubePlayer}>
      <form className={styles.AddressBar} onSubmit={onSubmit}>
        <label>Address</label>
        <div>
          <input
            type='text'
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder='Paste a link to a YouTube video'
          />
        </div>
        <input type='submit' value='Go' />
      </form>
      <div className={styles.VideoContainer}>
        {error && <div className={styles.Error}>Link invalid</div>}
        {video && (
          <YoutubeIframe src={video.src} type={video.type} time={video.time} />
        )}
        {!video && (
          <img
            alt='empty video'
            src={youtubeEmptyImage}
            width='100%'
            height='100%'
          />
        )}
      </div>
    </div>
  )
}

export default YoutubePlayer
