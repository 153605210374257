import React, { useEffect, useRef, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'

import { uploadFile } from '../../utils/FileUploader'
import { basePhotoUrl } from '../../utils/FirestoreUtils'
import background from '../Canvas/Background'
import folderIcon from '../../assets/mix-file-icon.png'

import styles from './VibeExplorer.module.scss'
import { useRoom } from '../Room/RoomProvider'
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react'
import { Timestamp } from 'firebase/firestore'
import DeleteMixModal from '../Modals/DeleteMixModal'
import { RoomData } from '../Room/Types'

const getFormattedNumber = (num: number) => {
  if (num < 10) return `0${num}`
  return num.toString()
}

const getFormattedDate = (date: Date): string => {
  return `${getFormattedNumber(date.getMonth() + 1)}/${getFormattedNumber(
    date.getDate()
  )}/${date.getFullYear()}`
}

const VibeExplorer: React.FC = () => {
  const {
    room,
    vibes,
    activeVibe: currentVibe,
    setActiveVibe,
    setShowCreateMix,
    saveVibe,
    saveRoom,
    viewOnly,
    setShowViewOnlyError,
  } = useRoom()

  const { roomId } = useParams()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedVibe, setSelectedVibe] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [vibeName, setVibeName] = useState('')
  const currentVibeRef = useRef(null)

  useEffect(() => {
    if (!vibes[selectedVibe] && selectedVibe !== 0) {
      setSelectedVibe(0)
    }
  }, [vibes, selectedVibe])

  const uploadBackgroundImage = useAsyncCallback(async (image: File) => {
    const filename = await uploadFile(
      image,
      vibes[selectedVibe].id + Date.now()
    )
    await saveVibe(
      {
        ...vibes[selectedVibe],
        background: `${basePhotoUrl}${filename}?alt=media`,
      },
      vibes[selectedVibe].id
    )
  })

  const onImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files.length === 0) return
    const image = files[0]
    uploadBackgroundImage.execute(image)
    setImageUrl(URL.createObjectURL(image))
  }

  const confirmDelete = useAsyncCallback(async () => {
    if (!room) return
    const newVibes = room.vibes.filter((r) => r !== vibes[selectedVibe].id)
    if (newVibes.length === 0) return
    await saveRoom(
      {
        ...(room as RoomData),
        vibes: newVibes,
      },
      roomId as string
    )
    setShowDeleteModal(false)
  })

  if (!currentVibe || !vibes[selectedVibe]) return null

  const renameMix = () => {
    if (!currentVibeRef.current) return
    const element = currentVibeRef.current as any
    element.scrollIntoView()
    setVibeName(vibes[selectedVibe].name)
    setIsEditing(true)
  }

  const finishEdit = async () => {
    setIsEditing(false)
    await saveVibe(
      {
        ...vibes[selectedVibe],
        name: vibeName,
        updatedAt: Timestamp.now(),
      },
      vibes[selectedVibe].id
    )
  }

  const onNameSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    finishEdit()
  }

  const openCreateMix = () => {
    if (viewOnly) {
      setShowViewOnlyError(true)
    } else {
      setShowCreateMix(true)
    }
  }

  const openImageSelect = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    if (viewOnly) {
      e.preventDefault()
      setShowViewOnlyError(true)
    }
  }

  const currentVibeIsSelected = currentVibe.id === vibes[selectedVibe]?.id

  return (
    <div className={styles.VibeExplorer}>
      {showDeleteModal && (
        <DeleteMixModal
          mixName={vibes[selectedVibe].name}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={confirmDelete.execute}
          loading={confirmDelete.loading}
        />
      )}
      <div>
        <div className={styles.ImagePreview}>
          {uploadBackgroundImage.loading && (
            <Dimmer active>
              <Loader />
            </Dimmer>
          )}
          <img
            alt='background'
            src={imageUrl || vibes[selectedVibe].background || background}
          />
        </div>

        {currentVibeIsSelected && (
          <div className={styles.FileUploader}>
            <label htmlFor='background' onClick={openImageSelect}>
              <div className='button'>Change wallpaper</div>
              <input
                type='file'
                id='background'
                name='background'
                accept='image/png, image/jpeg'
                onChange={onImageSelect}
              />
            </label>
          </div>
        )}

        <div className={styles.VibeList}>
          <div className={classNames(styles.ListHeader, styles.ListRow)}>
            <div>{vibes[selectedVibe].name}</div>
            {!viewOnly && (
              <Dropdown
                trigger={<div className={styles.EditButton}>Edit</div>}
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={renameMix}>Rename</Dropdown.Item>
                  {vibes.length > 1 && (
                    <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                      Delete
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div
            className={classNames(
              styles.ListHeader,
              styles.ListRow,
              styles.clearBackground
            )}
          >
            <div>Name</div>
            <div>Last set</div>
          </div>
          <div className={styles.ListBody}>
            {vibes.map((vibe, index) => (
              <div
                className={classNames(styles.ListRow, {
                  [styles.selected]: vibe.id === vibes[selectedVibe].id,
                })}
                key={vibe.id}
                onClick={() => setSelectedVibe(index)}
                ref={
                  vibe.id === vibes[selectedVibe].id
                    ? currentVibeRef
                    : undefined
                }
              >
                <div
                  className={classNames({
                    [styles.editing]:
                      vibe.id === vibes[selectedVibe].id && isEditing,
                  })}
                >
                  <img alt='folder icon' src={folderIcon} />
                  {vibe.id === vibes[selectedVibe].id && isEditing ? (
                    <form onSubmit={onNameSubmit}>
                      <input
                        type='text'
                        value={vibeName}
                        onChange={(e) => setVibeName(e.target.value)}
                        onBlur={finishEdit}
                        autoFocus
                      />
                    </form>
                  ) : (
                    <div>{vibe.name}</div>
                  )}
                </div>
                {!(vibe.id === vibes[selectedVibe].id && isEditing) ? (
                  <div>{getFormattedDate(vibe.updatedAt.toDate())}</div>
                ) : (
                  <div />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.Actions}>
        <button disabled={vibes.length >= 10} onClick={() => openCreateMix()}>
          Create a new mix
        </button>
        <button
          disabled={currentVibeIsSelected}
          onClick={() => setActiveVibe(vibes[selectedVibe])}
        >
          Set Mix
        </button>
      </div>
    </div>
  )
}

export default VibeExplorer
