import React from 'react'
import { useRoom } from '../Room/RoomProvider'
import { createImageElement } from '../../utils/ElementFactory'
import { ElementData } from '../Room/Types'
import AddGraphic from './AddGraphic'

import styles from './Graphic.module.scss'

type GraphicProps = {
  element: ElementData
}

const Graphic: React.FC<GraphicProps> = ({ element }) => {
  const { content } = element
  const { activeVibe, saveElement, saveVibe, viewOnly, setShowViewOnlyError } =
    useRoom()

  const addImage = async () => {
    if (!activeVibe) return
    if (viewOnly) {
      return setShowViewOnlyError(true)
    }

    const newElement = await saveElement(createImageElement() as any)
    await saveVibe(
      {
        ...activeVibe,
        elements: [...activeVibe.elements, newElement],
      },
      activeVibe.id
    )
  }

  return (
    <div className={styles.Graphic}>
      <div className={styles.AddImage}>
        <button className='transparent' onClick={addImage}>
          Add New Image
        </button>
      </div>
      {content.url ? (
        <img src={content.url} alt='graphic' width='100%' />
      ) : (
        <AddGraphic element={element} />
      )}
    </div>
  )
}

export default Graphic
