import React, { useMemo } from 'react'

import logo from '../../assets/wfh-logo.png'
import styles from './Welcome.module.scss'

type Props = {
  radioName: string
  onOpen: () => void
  backgroundImage: string
}

const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

const Welcome: React.FC<Props> = ({
  radioName,
  onOpen,
  backgroundImage,
}: Props) => {
  const onMobile = useMemo(() => isMobile(), [])

  return (
    <div className={styles.Welcome}>
      <img src={backgroundImage} alt='background' />
      <div className={styles.Content}>
        <div className={styles.Background} />
        <img src={logo} alt='logo' />
        {onMobile ? (
          <div className={styles.MobileMessage}>
            <p>WFH.FM is for desktop computers only.</p>
            <p>Enjoy some fresh air!</p>
          </div>
        ) : (
          <button onClick={onOpen}>Enter {radioName}</button>
        )}
      </div>
    </div>
  )
}

export default Welcome
