import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'

import { ElementType } from '../Room/Types'
import { useRoom } from '../Room/RoomProvider'
import About from '../About'
import Background from './Background'
import DesktopIcons from '../Room/DesktopIcons'
import DraggableElement from './DraggableElement'
import Menu from '../Menu'
import Share from '../Share'
import TaskBar from '../TaskBar'
import VibeExplorer from '../VibeExplorer'

import styles from './Canvas.module.scss'

const Canvas: React.FC = () => {
  const {
    activeVibe: vibe,
    deleteElement,
    showVibeExplorer,
    setShowVibeExplorer,
    activeElement,
    setActiveElement,
    showStartMenu,
    setShowStartMenu,
    showShare,
    setShowShare,
    setContextMenuPosition,
    contextMenuPosition,
    showAbout,
    setShowAbout,
  } = useRoom()

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const updateWindowSize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    const openContextMenu = (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setContextMenuPosition({ x: e.pageX, y: e.pageY })
    }
    window.addEventListener('resize', updateWindowSize)
    document.addEventListener('contextmenu', openContextMenu)
    return () => {
      window.removeEventListener('resize', updateWindowSize)
      document.removeEventListener('contextmenu', openContextMenu)
    }
  }, [setContextMenuPosition])

  const hideMenus = () => {
    setShowStartMenu(false)
    setContextMenuPosition(undefined)
  }

  if (!vibe) return null

  const hasEditKey = !!searchParams.get('edit_key')

  return (
    <div className={styles.Canvas} onClick={hideMenus}>
      <div className={styles.Background}>
        <img alt='background' src={vibe.background || Background} />
      </div>
      <DesktopIcons />
      {vibe.elements.map((element) => (
        <DraggableElement
          key={element}
          elementId={element}
          canvasSize={size}
          onClose={() => deleteElement(element)}
          onSelected={() => setActiveElement(element)}
          selected={activeElement === element}
          isLocal={!hasEditKey}
        />
      ))}
      {showVibeExplorer && (
        <DraggableElement
          canvasSize={size}
          onClose={() => setShowVibeExplorer(false)}
          onSelected={() => setActiveElement('vibes')}
          selected={activeElement === 'vibes'}
          defaultSize={{ width: 375, height: 479 }}
          defaultPosition={{ x: 20, y: 20 }}
          isLocal
          type={ElementType.VibeExplorer}
        >
          <VibeExplorer />
        </DraggableElement>
      )}
      {showAbout && (
        <DraggableElement
          canvasSize={size}
          onClose={() => setShowAbout(false)}
          onSelected={() => setActiveElement('about')}
          selected={activeElement === 'about'}
          defaultSize={{ width: 461, height: 460 }}
          defaultPosition={{ x: 200, y: 200 }}
          isLocal
          type={ElementType.About}
        >
          <About />
        </DraggableElement>
      )}
      {showShare && (
        <DraggableElement
          canvasSize={size}
          onClose={() => setShowShare(false)}
          onSelected={() => setActiveElement('share')}
          selected={activeElement === 'share'}
          defaultSize={{ width: 375, height: 350 }}
          defaultPosition={{ x: 70, y: 70 }}
          isLocal
          type={ElementType.Share}
        >
          <Share />
        </DraggableElement>
      )}
      <div
        className={classNames(styles.StartMenuContainer, {
          [styles.hidden]: !showStartMenu,
        })}
      >
        <Menu showTitle showIcons />
      </div>
      <div
        className={classNames(styles.ContextMenuContainer, {
          [styles.hidden]: !contextMenuPosition,
        })}
        style={{
          left: contextMenuPosition?.x + 'px',
          top: contextMenuPosition?.y + 'px',
        }}
      >
        <Menu showChangeWallpaper />
      </div>
      <TaskBar />
    </div>
  )
}

export default Canvas
