import { ElementType } from '../components/Room/Types'

export const createImageElement = (url = '') => ({
  position: {
    x: Math.random() * 70,
    y: Math.random() * 70,
  },
  size: {
    width: 300,
    height: 400,
  },
  type: ElementType.Image,
  content: {
    url,
  },
})
