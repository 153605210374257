import { getStorage, ref, uploadBytes } from 'firebase/storage'

export const uploadFile = async (file: File, filename: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
    const reader = new FileReader()
    reader.onloadend = async () => {
      if (reader.result) {
        const storage = getStorage()
        const filenameExt = `${filename}.${extension}`
        const fileUrl = `photos/${filenameExt}`
        const fileRef = ref(storage, `/${fileUrl}`)
        let bytes: Uint8Array | undefined
        if (reader.result instanceof ArrayBuffer) {
          bytes = new Uint8Array(reader.result)
          await uploadBytes(fileRef, bytes)
          resolve(filenameExt)
        }
      }
    }
    reader.onerror = () => reject(reader.error)
    reader.readAsArrayBuffer(file)
  })
}
