import React from 'react'

import closeIcon from '../../assets/close-icon.svg'
import styles from './Modals.module.scss'

type Props = {
  children: React.ReactNode
  title: React.ReactNode
  onClose: () => void
}

const Window: React.FC<Props> = ({ children, title, onClose }: Props) => {
  return (
    <div className={styles.Window}>
      <div className={styles.InnerContainer}>
        <div className={styles.Title}>
          {title}
          <img
            src={closeIcon}
            onClick={onClose}
            alt='close'
            className={styles.CloseIcon}
          />
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </div>
  )
}

export default Window
