import React, { useEffect, useState } from 'react'
import Divider from './Divider'

import spotifyIcon from '../../assets/spotify-icon.svg'
import youtubeIcon from '../../assets/youtube-icon.svg'
import remotionIcon from '../../assets/remotion-icon.svg'
import styles from './TaskBar.module.scss'
import { useParams } from 'react-router-dom'
import { useRoom } from '../Room/RoomProvider'

const formatTime = (date: Date): string => {
  let suffix = 'AM'
  let hours = date.getHours()
  if (hours > 11) {
    suffix = 'PM'
  }
  if (hours > 12) {
    suffix = 'PM'
    hours -= 12
  }
  const minutes = date.getMinutes()
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${suffix}`
}

const TaskBar: React.FC = () => {
  const { roomId } = useParams()
  const [currentDate, setCurrentDate] = useState(new Date())
  const {
    setShowCreateMix,
    setShowVibeExplorer,
    setActiveElement,
    youtubeElementId,
    spotifyElementId,
    showStartMenu,
    setShowStartMenu,
    setShowAbout,
    room,
    viewOnly,
    setShowViewOnlyError,
    vibes,
  } = useRoom()

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const openCreateMix = () => {
    if (viewOnly) {
      setShowViewOnlyError(true)
    } else {
      setShowCreateMix(true)
      setShowVibeExplorer(true)
    }
  }

  return (
    <div className={styles.TaskBar}>
      <div>
        <button
          onClick={(e) => {
            e.stopPropagation()
            setShowStartMenu(!showStartMenu)
          }}
        >
          Start
        </button>
        <Divider />
        <button onClick={openCreateMix} disabled={vibes.length >= 10}>
          + Create mix
        </button>
        <Divider />
        <img
          className={styles.Icon}
          alt='spotify button'
          src={spotifyIcon}
          onClick={() => setActiveElement(spotifyElementId)}
        />
        <img
          className={styles.Icon}
          alt='youtube button'
          src={youtubeIcon}
          onClick={() => setActiveElement(youtubeElementId)}
        />
      </div>

      <div>
        <div>{room?.name || roomId}</div>
        <Divider />
        <img
          alt='remotion button'
          src={remotionIcon}
          onClick={() => setShowAbout(true)}
          style={{ cursor: 'pointer' }}
        />
        <Divider />
        <div>{formatTime(currentDate)}</div>
      </div>
    </div>
  )
}

export default TaskBar
