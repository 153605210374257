import { doc, DocumentData, DocumentSnapshot, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-async-hook'

import { functions } from '../../App'
import { initializeFirestore } from '../../utils/FirestoreUtils'
import { ElementData, ElementType, RoomData, VibeData } from './Types'
import { initializeSegment } from '../../utils/Segment'

const RoomContext = createContext({} as any)

export type RoomContextType = {
  room: RoomData | undefined
  setRoom: (room: RoomData) => void
  vibes: VibeData[]
  setVibes: (vibes: VibeData[]) => void
  activeVibe: VibeData | undefined
  setActiveVibe: (vibe: VibeData) => void
  createRoom: (
    roomId: string,
    data?: Partial<RoomData>
  ) => Promise<{ password: string }>
  saveRoom: (data: RoomData, roomId: string) => Promise<any>
  saveElement: (data: ElementData, elementId?: string) => Promise<string>
  createVibe: (roomId: string, name: string) => Promise<string>
  saveVibe: (data: VibeData, vibeId?: string) => Promise<string>
  deleteElement: (elementId: string) => Promise<any>
  showVibeExplorer: boolean
  setShowVibeExplorer: (value: boolean) => void
  showShare: boolean
  setShowShare: (value: boolean) => void
  showCreateMix: boolean
  setShowCreateMix: (value: boolean) => void
  getRoom: (roomId: string) => Promise<DocumentSnapshot<DocumentData>>
  getVibe: (vibeId: string) => Promise<DocumentSnapshot<DocumentData>>
  activeVibeId: string
  onElementLoad: (element: ElementData) => void
  spotifyElementId: string
  youtubeElementId: string
  activeElement: string | undefined
  setActiveElement: (elementId: string | undefined) => void
  showStartMenu: boolean
  setShowStartMenu: (value: boolean) => void
  contextMenuPosition: ContextMenuPosition | undefined
  setContextMenuPosition: (position: ContextMenuPosition | undefined) => void
  showAbout: boolean
  setShowAbout: (value: boolean) => void
  showViewOnlyError: boolean
  setShowViewOnlyError: (value: boolean) => void
  redirectPassword: string
  setRedirectPassword: (password?: string) => void
  viewOnly: boolean
  trackEvent: (event: AnalyticsEvent, data: any) => void
  analyticsReady: boolean
}

export const useRoom = (): RoomContextType => useContext(RoomContext)

type Props = {
  children: React.ReactNode
}

type ContextMenuPosition = {
  x: number
  y: number
}

export enum AnalyticsEvent {
  JoinRoom = 'join_room',
  UserJoin = 'user_join',
}

const segmentWriteKey = 'XNa8WVr8dqGZKJksNy7PwUwvsa7T8zo2'

const RoomProvider: React.FC<Props> = (props: Props) => {
  const { children } = props

  const [room, setRoom] = useState<RoomData | undefined>(undefined)
  const [vibes, setVibes] = useState<VibeData[]>([])
  // Currently selected mix (already fetched)
  const [activeVibe, setActiveVibe] = useState<VibeData | undefined>(undefined)
  const [showVibeExplorer, setShowVibeExplorer] = useState(true)
  const [showShare, setShowShare] = useState(false)
  const [showCreateMix, setShowCreateMix] = useState(false)
  const [activeVibeId, setActiveVibeId] = useState('')
  const [spotifyElementId, setSpotifyElementId] = useState('')
  const [youtubeElementId, setYoutubeElementId] = useState('')
  const [segmentLoaded, setSegmentLoaded] = useState(false)
  const [activeElement, setActiveElement] = useState<string | undefined>(
    undefined
  )
  const [showStartMenu, setShowStartMenu] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showViewOnlyError, setShowViewOnlyError] = useState(false)
  const [contextMenuPosition, setContextMenuPosition] = useState<
    ContextMenuPosition | undefined
  >(undefined)
  const [redirectPassword, setRedirectPassword] = useState<string | undefined>(
    undefined
  )
  // Using this one for selecting "future" mixes (they haven't been fetched yet)
  const [vibeToSelect, setVibeToSelect] = useState('')

  const { roomId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const password = searchParams.get('edit_key')
  const appWindow = window as any

  const trackEvent = useCallback((event: AnalyticsEvent, data?: any) => {
    appWindow.analytics.track(event, data)
  }, [])

  useEffect(() => {
    initializeSegment(segmentWriteKey, () => {
      setSegmentLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (!segmentLoaded) return
    trackEvent(AnalyticsEvent.UserJoin)
  }, [segmentLoaded])

  useAsync(async () => {
    if (roomId && password) {
      const validatePasswordFunc = httpsCallable(functions, 'validatePassword')
      try {
        await validatePasswordFunc({ roomId, password })
      } catch (e: any) {
        if (e.code === 'functions/permission-denied') {
          navigate(`/${roomId}`, { replace: true })
        }
      }
    }
  }, [roomId, password])

  useEffect(() => {
    if (vibes.length && room) {
      const selectedVibe = vibes.find((item) => item.id === vibeToSelect)
      const vibe = vibes.find((item) => item.id === activeVibeId)
      if (selectedVibe && activeVibe !== selectedVibe) {
        setVibeToSelect('')
        saveActiveVibe(selectedVibe)
      } else if (vibe && activeVibe !== vibe) {
        saveActiveVibe(vibe)
      } else if (!activeVibeId || !vibe) {
        const vibeId = room.vibes[0]
        const vibe = vibes.find((v) => v.id === vibeId)
        saveActiveVibe(vibe || vibes[0])
      }
    }
  }, [vibes, activeVibeId, activeVibe, room, vibeToSelect])

  const saveActiveVibe = (vibe: VibeData) => {
    setActiveVibeId(vibe.id)
    setActiveVibe(vibe)
  }

  const { roomsCollectionRef, vibesCollectionRef } = initializeFirestore()

  const createRoom = async (
    roomId: string,
    data?: RoomData
  ): Promise<{ password: string }> => {
    const createRoomFunc = httpsCallable(functions, 'createRoom')
    const result = (await createRoomFunc({ id: roomId, room: data })) as any
    return { password: result.data.password }
  }

  const saveRoom = (data: RoomData, roomId: string): Promise<any> => {
    const saveRoomFunc = httpsCallable(functions, 'saveRoom')
    return saveRoomFunc({ id: roomId, updates: data, password })
  }

  const createVibe = async (roomId: string, name: string): Promise<string> => {
    const createVibeFunc = httpsCallable(functions, 'createVibe')
    const result = (await createVibeFunc({
      roomId,
      name,
      background: activeVibe?.background,
      password,
    })) as any
    setVibeToSelect(result.data.id)
    return result.data.id
  }

  const saveVibe = async (data: VibeData, vibeId?: string): Promise<string> => {
    const saveVibeFunc = httpsCallable(functions, 'saveVibe')
    const result = (await saveVibeFunc({
      id: vibeId,
      updates: { ...data, roomId },
      password,
    })) as any
    return result.data.id
  }

  const saveElement = async (
    data: ElementData,
    elementId?: string
  ): Promise<string> => {
    const saveElementFunc = httpsCallable(functions, 'saveElement')
    const result = (await saveElementFunc({
      id: elementId,
      updates: {
        ...data,
        roomId,
      },
      password,
    })) as any
    return result.data.id
  }

  const deleteElement = async (elementId: string) => {
    if (!activeVibe) return
    if (!password) {
      return setShowViewOnlyError(true)
    }

    const deleteElementFunc = httpsCallable(functions, 'deleteElement')
    return deleteElementFunc({
      elementId,
      vibeId: activeVibe.id,
      password,
      roomId,
    })
  }

  const getRoom = (roomId: string): Promise<DocumentSnapshot<DocumentData>> => {
    return getDoc(doc(roomsCollectionRef, roomId))
  }

  const getVibe = (vibeId: string): Promise<DocumentSnapshot<DocumentData>> => {
    return getDoc(doc(vibesCollectionRef, vibeId))
  }

  const onElementLoad = (element: ElementData) => {
    if (element.type === ElementType.Spotify) {
      setSpotifyElementId(element.id)
      setActiveElement(element.id)
    } else if (element.type === ElementType.Youtube) {
      setYoutubeElementId(element.id)
    }
  }

  return (
    <RoomContext.Provider
      value={{
        room,
        setRoom,
        vibes,
        setVibes,
        activeVibe,
        setActiveVibe: saveActiveVibe,
        createRoom,
        saveRoom,
        saveElement,
        createVibe,
        saveVibe,
        deleteElement,
        showVibeExplorer,
        setShowVibeExplorer,
        showShare,
        setShowShare,
        showCreateMix,
        setShowCreateMix,
        getRoom,
        getVibe,
        activeVibeId,
        onElementLoad,
        spotifyElementId,
        youtubeElementId,
        activeElement,
        setActiveElement,
        showStartMenu,
        setShowStartMenu,
        contextMenuPosition,
        setContextMenuPosition,
        showAbout,
        setShowAbout,
        showViewOnlyError,
        setShowViewOnlyError,
        redirectPassword,
        setRedirectPassword,
        viewOnly: !password,
        trackEvent,
        analyticsReady: segmentLoaded,
      }}
    >
      {children}
    </RoomContext.Provider>
  )
}

export default RoomProvider
