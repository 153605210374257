import { collection, getFirestore } from 'firebase/firestore'

export const basePhotoUrl =
  'https://firebasestorage.googleapis.com/v0/b/company-radio.appspot.com/o/photos%2F'

export const roomsCollectionName = 'rooms'
export const vibesCollectionName = 'vibes'
export const elementsCollectionName = 'elements'
export const passwordsCollectionName = 'passwords'

export const initializeFirestore = () => {
  const db = getFirestore()
  const roomsCollectionRef = collection(db, roomsCollectionName)
  const vibesCollectionRef = collection(db, vibesCollectionName)
  const elementsCollectionRef = collection(db, elementsCollectionName)
  const passwordsCollectionRef = collection(db, passwordsCollectionName)

  return {
    db,
    roomsCollectionRef,
    vibesCollectionRef,
    elementsCollectionRef,
    passwordsCollectionRef,
  }
}
