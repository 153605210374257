import React from 'react'
import { createImageElement } from '../../../utils/ElementFactory'
import { useRoom } from '../RoomProvider'
import { ElementData } from '../Types'

import styles from './DesktopIcons.module.scss'
import mixesImage from '../../../assets/mixes-desktop-icon.png'
import spotifyImage from '../../../assets/spotify-desktop-icon.png'
import youtubeImage from '../../../assets/youtube-desktop-icon.png'
import giphyImage from '../../../assets/image-desktop-icon.png'
import Icon from './Icon'

const DesktopIcons: React.FC = () => {
  const {
    saveElement,
    saveVibe,
    activeVibe,
    setShowVibeExplorer,
    setActiveElement,
    youtubeElementId,
    spotifyElementId,
    viewOnly,
    setShowViewOnlyError,
  } = useRoom()

  const appendElementToVibe = async (elementData: ElementData) => {
    if (!activeVibe) return
    if (viewOnly) {
      return setShowViewOnlyError(true)
    }
    const newElement = await saveElement(elementData)
    await saveVibe(
      {
        ...activeVibe,
        elements: [...activeVibe.elements, newElement],
      },
      activeVibe.id
    )
  }

  return (
    <div className={styles.DesktopIcons}>
      <Icon onClick={() => setShowVibeExplorer(true)}>
        <img src={mixesImage} alt='mixes app' width={70} height={64} />
      </Icon>
      <Icon onClick={() => setActiveElement(spotifyElementId)}>
        <img src={spotifyImage} alt='mixes app' width={64} height={64} />
      </Icon>
      <Icon onClick={() => setActiveElement(youtubeElementId)}>
        <img src={youtubeImage} alt='mixes app' width={64} height={45} />
      </Icon>
      <Icon onClick={() => appendElementToVibe(createImageElement() as any)}>
        <img src={giphyImage} alt='mixes app' width={64} height={64} />
      </Icon>
    </div>
  )
}

export default DesktopIcons
