import { Timestamp } from 'firebase/firestore'

export enum ElementType {
  Youtube = 'Youtube',
  Spotify = 'Spotify',
  Image = 'Image',
  VibeExplorer = 'Vibe Explorer',
  About = 'About',
  Share = 'Share',
}

export type RoomData = {
  createdAt: Timestamp
  vibes: string[]
  name?: string
  redirectTo?: string
}

export type VibeData = {
  id: string
  name: string
  background: string
  elements: string[]
  updatedAt: Timestamp
  roomId: string
}

export type ElementData = {
  id: string
  position: {
    x: number
    y: number
  }
  size: {
    width: number
    height: number
  }
  type: ElementType
  content: {
    url?: string
  }
  roomId: string
}
