import React from 'react'

import aboutImage from '../../assets/about-wfh.png'
import styles from './About.module.scss'

const About: React.FC = () => {
  return (
    <div className={styles.About}>
      <div className={styles.ImageContainer}>
        <img src={aboutImage} alt='about' width='429' height='208' />
      </div>

      <p>
        WFH.fm is a place where teams can share vibes of their new (home) office
        through their favorite songs, videos, and images.
      </p>

      <p>
        <strong>Brought to you by Remotion</strong>
        <br />
        <a href='https://remotion.com' target='_blank' rel='noreferrer'>
          Remotion
        </a>{' '}
        is a virtual office for teams to connect like everyone&apos;s together.
      </p>

      <a href='https://remotion.com' target='_blank' rel='noreferrer'>
        <button>Learn more</button>
      </a>
    </div>
  )
}

export default About
