import React from 'react'

type SpotifyIframeProps = {
  src: string
}

const SpotifyIframe: React.FC<SpotifyIframeProps> = ({ src }) => {
  return (
    <iframe
      title='Spotify player'
      src={src}
      width='100%'
      height='100%'
      frameBorder='0'
      allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
    ></iframe>
  )
}

export default SpotifyIframe
