import React from 'react'
import { Modal } from 'semantic-ui-react'

import Window from './Window'
import errorIcon from '../../assets/error-icon.png'
import styles from './Modals.module.scss'
import { useRoom } from '../Room/RoomProvider'
import { useNavigate } from 'react-router-dom'

const ViewOnlyErrorModal: React.FC = () => {
  const navigate = useNavigate()
  const { setShowViewOnlyError } = useRoom()

  const onCreateOwn = () => {
    onClose()
    navigate('/')
  }

  const onClose = () => {
    setShowViewOnlyError(false)
  }

  return (
    <Modal
      open
      onClose={onClose}
      size='mini'
      className={styles.ViewOnlyErrorModal}
    >
      <Window title='Access denied' onClose={onClose}>
        <div className={styles.Message}>
          <img src={errorIcon} alt='warning' />
          <div>
            <p>To customize this station, use the secret edit link.</p>
            <p>
              If you don't have the edit link, ask your teammates or reach out
              to support@remotion.com.
            </p>
          </div>
        </div>

        <div className={styles.Actions}>
          <button onClick={onCreateOwn}>Create your own station</button>
          <button onClick={onClose}>OK</button>
        </div>
      </Window>
    </Modal>
  )
}

export default ViewOnlyErrorModal
