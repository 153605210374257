import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { doc, onSnapshot, query, where, documentId } from 'firebase/firestore'
import { useAsync } from 'react-async-hook'

import backgroundImage from '../../assets/groovy-background.jpeg'
import Canvas from '../Canvas'
import { VibeData } from './Types'
import { Loader } from 'semantic-ui-react'
import { initializeFirestore } from '../../utils/FirestoreUtils'
import { AnalyticsEvent, useRoom } from './RoomProvider'
import CreateMixModal from '../Modals/CreateMixModal'
import Welcome from '../Welcome'
import ViewOnlyErrorModal from '../Modals/ViewOnlyErrorModal'

const Room: React.FC = () => {
  const navigate = useNavigate()
  const { roomId } = useParams()
  const [hasClicked, setHasClicked] = useState(
    !!sessionStorage.getItem('hasClicked')
  )

  const {
    room,
    setRoom,
    setVibes,
    activeVibe,
    createRoom,
    showCreateMix,
    getRoom,
    showViewOnlyError,
    trackEvent,
    redirectPassword,
    setRedirectPassword,
    analyticsReady,
  } = useRoom()

  const { roomsCollectionRef, vibesCollectionRef } = initializeFirestore()

  const createInitialRoom = async (roomId: string) => {
    const { password } = await createRoom(roomId)

    navigate(`/${roomId}?edit_key=${password}`, { replace: true })
  }

  useAsync(async () => {
    const roomSnap = await getRoom(roomId as string)

    if (!roomSnap.exists()) {
      await createInitialRoom(roomId as string)
    }

    onSnapshot(doc(roomsCollectionRef, roomId as string), (doc) => {
      setRoom(doc.data() as any)
    })
  }, [roomId])

  useEffect(() => {
    if (!analyticsReady) return
    trackEvent(AnalyticsEvent.JoinRoom, { roomId })
  }, [roomId, analyticsReady])

  useEffect(() => {
    if (!room) return

    if (room.redirectTo) {
      let url = `/${room.redirectTo}`

      if (redirectPassword) {
        url += `?edit_key=${redirectPassword}`
      }
      navigate(url, { replace: true })

      setRedirectPassword()
    }

    const q = query(vibesCollectionRef, where(documentId(), 'in', room.vibes))
    const vibesOrderMap: any = room.vibes.reduce((result, vibeId, index) => {
      return {
        ...result,
        [vibeId]: index,
      }
    }, {})
    const unsub = onSnapshot(q, (snapshot) => {
      const newVibes: VibeData[] = []
      snapshot.forEach((doc) => {
        newVibes[vibesOrderMap[doc.id]] = {
          id: doc.id,
          ...(doc.data() as any),
        }
      })
      setVibes(newVibes)
    })
    return () => {
      unsub()
    }
  }, [room, setVibes])

  if (!activeVibe) {
    return <Loader active />
  }

  if (!hasClicked) {
    return (
      <Welcome
        radioName={room?.name || roomId || 'radio'}
        backgroundImage={activeVibe?.background || backgroundImage}
        onOpen={() => setHasClicked(true)}
      />
    )
  }

  return (
    <div>
      {showCreateMix && <CreateMixModal />}
      {showViewOnlyError && <ViewOnlyErrorModal />}
      <Canvas />
    </div>
  )
}

export default Room
