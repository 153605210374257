import React from 'react'

import Room from './Room'
import RoomProvider from './RoomProvider'

const RoomWrapper: React.FC = () => (
  <RoomProvider>
    <Room />
  </RoomProvider>
)

export default RoomWrapper